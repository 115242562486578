module.exports = {
  siteTitle: 'ООО «ДФ-Лоджистикс»', // <title>
  manifestName: 'ДФ-Лоджистикс',
  manifestShortName: 'ДФ-Лоджистикс', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  authorName: 'ООО «ДФ-Лоджистикс»',
  // social
  socialLinks: [
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:df.logistics.msk@gmail.com',
    },
  ],
};
